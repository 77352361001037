import { combineReducers } from 'redux';
import auth from './auth.reducer';
import subcontractor from './subcontractor.reducer';
import contract from './contract.reducer';
import modal from './ui/modal.reducer';
import message from './ui/message.reducer';
import obligated from './obligated.reducer';
import documents from './documents.reducer';
import analyticsReducer from './analytics.reducer';
import dossierCEEReducer from './dossierCEE.reducer';
import formReducer from './ui/form.reducer';
import dialogReducer from './ui/dialog.reducer';
import cancelledDocument from './cancelledDocument.reducer';
import accountsReducer from './accounts.reducer';
import productVerificationReducer from './productVerification.reducer';
import consumerMediators from './consumer-mediator.reducer';
import delegateeInvoiceReducer from './delegateeInvoice.reducer';
import dossierMRPReducer from './dossierMPR.reducer';
import organizationReducer from './organization.reducer';
import operationsReducer from './operations.reducer';
import documentVerificationReducer from './document-verification.reducer';

export default combineReducers({
    auth,
    contract,
    organizationState: organizationReducer,
    subcontractor,
    obligated,
    documents,
    ui: combineReducers({
        modalState: modal,
        message,
        formState: formReducer,
        dialogState: dialogReducer,
    }),
    analyticsState: analyticsReducer,
    dossiersCEEState: dossierCEEReducer,
    cancelledDocument,
    accountsState: accountsReducer,
    productVerificationState: productVerificationReducer,
    consumerMediators,
    delegateeInvoicesState: delegateeInvoiceReducer,
    dossierMRPState: dossierMRPReducer,
    operationsState: operationsReducer,
    documentsState: documentVerificationReducer,
});
