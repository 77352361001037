import { GET_DOCUMENTS_REQUEST, GET_DOCUMENTS_SUCCESS, GET_DOCUMENTS_FAILURE } from '../types/document-verification.type';

const initialState = {
    data: null,
    isLoading: false,
    error: null,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_DOCUMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                data: payload,
                isLoading: false,
            };
        case GET_DOCUMENTS_FAILURE:
            return {
                ...state,
                error: payload,
                isLoading: false,
            };
        default:
            return state;
    }
};
